<template>
  <div class="fgg-container text-center">
    <img src="../assets/Logo_Dark.svg" alt="Logo" class="mx-auto w-36 mb-10" />
    <p>Seite konnte nicht gefunden werden.</p>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped></style>
